<template>
  <v-container>
    <v-row>
      <v-col class="mb-4">
        <h1 class="text-h6 mb-3 primary--text">¿POR QUÉ NO PENALIZAMOS LA MORA?</h1>

        <p>
          Porque <a href="/MICROWD_Informe.pdf" class="white--text" target="_blank"><b>Esther Duflo</b></a> tiene razón y las microfinanzas,
          penalizando la mora, no mejoran la vida de la gente. Nosotros, ajustado el tipo de interés, conseguimos dar rentabilidad a nuestro
          inversor a la vez que
          <span class="secondary--text">la mujer aumenta sus ingresos por ¡DOS! en 24 meses.</span>
        </p>
      </v-col>

      <!-- <v-col cols="12">
        <v-img :src="require('@/assets/images/not_penalize_the_delay.svg')" class="my-3" contain height="200" />
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NotPenalizeTheDelay",
  created() {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.$emit("setImageBg", {
        backgroundImage: "url(" + require("@/assets/images/backgrounds/bgmobile-notPenalizeTheDelay.png") + ")"
      });
    } else {
      this.$emit("setImageBg", {
        backgroundImage: "url(" + require("@/assets/images/backgrounds/bg-notPenalizeTheDelay.png") + ")"
      });
    }
  }
};
</script>
